<template>
  <div>
    <v-card-text>
      <div v-if="items">
        <v-card outlined class="white pa-2">
          <v-list>
            <template v-for="(item, index) in items">
              <v-list-item :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ item.Incentivo }} </v-list-item-title>
                  <v-list-item-subtitle>{{
                    item.FechaAplicado | date
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <download-csv
                    class="
                      ma-1
                      v-btn v-btn--block v-btn--has-bg
                      theme--light
                      v-size--default
                    "
                    :labels="headers"
                    :fields="fields"
                    :data="item.IncentivoCalculoDetalle"
                    :name="item.Incentivo + ' Resultado.csv'"
                  >
                    Exportar
                  </download-csv>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index !== items.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list>
        </v-card>
      </div>
      <div v-else>
        <v-alert text type="info">No hay data para mostrar</v-alert>
      </div>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-spacer></v-spacer>
        <v-divider></v-divider>

        <br />
        <v-select
          :items="periodoOptions"
          v-model="periodoSelected"
          item-text="NombreMes"
          item-value="FechaInicio"
          append-icon="keyboard_arrow_down"
          hint="Filtrar por corte"
          hide-details
          :loading="loadingSearch"
        ></v-select>
        <br />
        <div class="mb-5 text-right">
          <v-btn block class="ma-1" depressed color="primary" @click="get">
            <v-icon left>search</v-icon>Buscar
            <span slot="loader">Buscando...</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import apiComision from "@/api/comisiones";

export default {
  data() {
    return {
      drawerRight: true,
      periodoSelected: null,
      item: null,
      loadingSearch: false,
      items: null,
      selected: null,
      fields: [
        "NumeroOS",
        "CodSucursal",
        "Telefono",
        "FechaActivacion",
        "TipoProducto",
        "Producto",
        "Comision",
        "Logro",
        "Objetivo",
        "MotivoNoPago",
      ],
      headers: [
        { value: "ORDEN", key: "NumeroOS" },
        { value: "SUCURSAL", key: "CodSucursal" },
        { value: "TELEFONO", key: "Telefono" },
        { value: "FECHA ACTIVACION", key: "FechaActivacion" },
        { value: "TIPO PRODUCTO", key: "TipoProducto" },
        { value: "PRODUCTO", key: "Producto" },
        { value: "COMISION", key: "Comision" },
        { value: "LOGRO", key: "Logro" },
        { value: "OBJETIVO", key: "Objetivo" },
        { value: "MOTIVO", key: "MotivoNoPago" },
      ],
    };
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    get() {
      this.loadingSearch = true;
      apiComision.getIncentivos(this.periodoSelected).then((response) => {
        this.loadingSearch = false;
        if (response.data.length > 0) {
          this.items = response.data;
          this.selected = 0;
        }
      });
    },
    download() {},
  },
  computed: {
    currentUser() {
      return this.$store.getters.user;
    },
    periodoOptions() {
      return this.$store.getters.periodos;
    },
  },
  mounted() {
    this.periodoSelected = this.periodoOptions[0].FechaInicio;
  },
  watch: {
    selected: function () {
      if (this.selected != undefined) {
        this.item = this.items[this.selected];
      } else {
        this.item = null;
      }
    },
  },
};
</script>